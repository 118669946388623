// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import { db } from '@/config/firebase';
import _ from 'lodash';

export default {
	namespace: true,

	state: {
		connections: {},
		currConnection: {},
	},

	mutations: {
		SET_ALL_CONNECTIONS(state, connections) {
			state.connections = connections
		},
		SET_CURR_CONNECTION(state, connection) {
			state.currConnection = connection
		},
	},

	actions: {
		setAllConnections(vuexContext, connections) {
			vuexContext.commit('SET_ALL_CONNECTIONS', connections);
		},
		updateAllConnections(vuexContext, newConnections) {
			let connections = vuexContext.getters.connections;

			_.forEach(newConnections, (value, key) => {
				connections[key] = value;
			});

			vuexContext.commit('SET_ALL_CONNECTIONS', connections);
		},

		async addConnection({
			rootState
		}, param) {
			let currUserId = rootState.auth.email;
			let newConnection = {
				company: param.company,
				companyId: param.companyId,
				connectedCompany: param.connectedCompany,
				connectedCompanyId: param.connectedCompanyId,
				connections: param.connections,
				storageLocations: param.storageLocations,
				isActive: param.isActive,
				notes: param.notes,
				others: param.others,
				hasDelayedTransfer: param.hasDelayedTransfer ? param.hasDelayedTransfer : false,
				delayedTransferDays: param.delayedTransferDays ? parseInt(param.delayedTransferDays) : 0,
				dateCreated: DateUtil.getCurrentTimestamp(),
				createdBy: currUserId,
				dateUpdated: DateUtil.getCurrentTimestamp(),
				updatedBy: currUserId
			}

			return db.collection("connections").add(newConnection)
				.then((docRef) => {
					newConnection.id = docRef.id;
					return {
						isSuccess: true,
						data: newConnection
					};
				}).catch((error) => {
					return {
						isSuccess: false,
						data: error
					};
				});
		},

		async updateConnection({
			rootState
		}, param) {
			let currUserId = rootState.auth.email;
			let selConnection = {
				id: param.id,
				company: param.company,
				companyId: param.companyId,
				connectedCompany: param.connectedCompany,
				connectedCompanyId: param.connectedCompanyId,
				connections: param.connections,
				storageLocations: param.storageLocations,
				isActive: param.isActive,
				notes: param.notes,
				others: param.others,
				hasDelayedTransfer: param.hasDelayedTransfer ? param.hasDelayedTransfer : false,
				delayedTransferDays: param.delayedTransferDays ? parseInt(param.delayedTransferDays) : 0,
				dateCreated: param.dateCreated,
				createdBy: param.updatedBy,
				dateUpdated: DateUtil.getCurrentTimestamp(),
				updatedBy: currUserId
			}

			return db.collection("connections").doc(param.id).set(selConnection)
				.then(() => {
					return {
						isSuccess: true,
						data: selConnection
					};
				}).catch((error) => {
					return {
						isSuccess: false,
						data: error
					};
				});
		}
	},

	getters: {
		connections(state) {
			if (!_.isEmpty(state.connections)) {
				return state.connections;
			}
			return {};
		},
		currConnection(state) {
			if (!_.isEmpty(state.currConnection)) {
				return state.currConnection;
			}
			return {};
		}
	}
}
