const moment = require('moment-timezone');
const defaultTimeZone = 'Asia/Manila';

// Set timezone globally
moment.tz.setDefault(defaultTimeZone);

const roundDate = (timeStamp) => {
	let d = new Date(timeStamp)
	d.setHours(0)
	d.setMinutes(0)
	d.setSeconds(0)
	d.setMilliseconds(0)
	return d.getTime()
};

const addDays = (date, days = 1) => {
	const result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
};

const getDateRangeArray = (start, end, range = []) => {
	if (start > end) return range;
	const next = addDays(start, 1);
	return getDateRangeArray(next, end, [...range, start]);
};

function startDateTimeStamp(date) {
	if (Object.prototype.toString.call(date) === '[object Date]') {
		return roundDate(date.getTime())
	} else {
		return roundDate(date);
	}
}

function endDateTimeStamp(date) {
	let addOneDay = 60 * 60 * 24 * 1000
	if (Object.prototype.toString.call(date) === '[object Date]') {
		return roundDate(date.getTime()) + addOneDay - 1
	} else {
		return roundDate(date) + addOneDay - 1
	}
}

function getDatesBetween(startDate, endDate) {
	let start = new Date(startDate);
	let end = new Date(endDate);
	let dateArray = [];

	// Loop through each date, pushing it to the array
	while (start <= end) {
		// Clone the date and push to array
		dateArray.push(new Date(start));
		// Move to the next day
		start.setDate(start.getDate() + 1);
	}

	return dateArray;
}

function getMonthDateRange(timestamp) {
    // Create a date object from the timestamp
    const date = new Date(timestamp);

    // Get the first day of the month
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

    // Get the last day of the month
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return {
        start: firstDay.getTime(),
        end: lastDay.getTime()
    };
}


export const DateUtil = {
	getCurrentTimestamp() {
		return new Date().getTime();
	},

	startDateTimeStamp,
	endDateTimeStamp,
	getDatesBetween,
	getMonthDateRange,

	roundDate(timeStamp) {
		return roundDate(timeStamp);
	},

	// Date Formats
	getFormattedDateShorten(dateStr) {
		if (dateStr) {
			let format = 'MMM DD, YYYY'
			return moment(dateStr).tz('Asia/Manila').format(format)
		}
		return ''
	},
	getFormattedDate(dateStr) {
		if (dateStr) {
			let format = 'MMMM DD, YYYY'
			return moment(dateStr).tz('Asia/Manila').format(format)
		}
		return ''
	},
	getFormattedDateWithTime(dateStr) {
		if (dateStr) {
			let format = 'MMMM DD, YYYY, hh:mm A'
			return moment(dateStr).tz('Asia/Manila').format(format);
		}
		return '';
	},
	getDateInDDMMYYYYHHSSFormat(timeStamp) {
		let dateTime = new Date(timeStamp);
		return moment(dateTime).format('DDMMYYYYHHmmss');
	},
	getDateInMMDDYYYYFormat(timeStamp) {
		let dateTime = new Date(timeStamp);
		return moment(dateTime).format('MMDDYYYY');
	},

	getNoOfDays(dateFrom, dateTo) {
		// make sure that the inputs are in date format
		dateFrom = new Date(startDateTimeStamp(dateFrom));
		dateTo = new Date(endDateTimeStamp(dateTo));
		// get the difference in days
		let diff = Math.abs(dateFrom.getTime() - dateTo.getTime());
		let day = diff / (1000 * 60 * 60 * 24);
		return parseInt(day)
	},

	getCurrentMonth() {
		return parseInt(moment().tz('Asia/Manila').format('M'));
	},

	getCurrentYear() {
		return parseInt(moment().tz('Asia/Manila').format('YYYY'));
	},

	// Param: dateStrng with "mm-dd-yyyy" format
	getTimestamp(dateString) {
		let strArr = dateString.includes("-") ? dateString.split('-') : dateString.split('/');
		let month = parseInt(strArr[0]) - 1;
		let day = parseInt(strArr[1]);
		let year = parseInt(strArr[2]);
		let date = new Date();
		date.setFullYear(year);
		date.setMonth(month);
		date.setDate(day);
		return date.getTime();
	},

	getMonthName(month) {
		return moment().month(month - 1).format('MMMM');
	},

	getTimestampRangeForMonth: (year, month) => {
		// Ensure month is in the range 1-12
		month = Math.max(1, Math.min(12, month));

		// Create Date objects for the first and last day of the month
		const firstDay = new Date(year, month - 1, 1); // month is 0-indexed
		const lastDay = new Date(year, month, 0); // day 0 is the last day of the previous month

		// Get timestamps for the first and last day of the month
		const firstDayTimestamp = firstDay.getTime();
		const lastDayTimestamp = lastDay.getTime() + 86400000 - 1; // Add 1 day and subtract 1 millisecond to get the end of the last day

		return { start: firstDayTimestamp, end: lastDayTimestamp };
	},

	getAdustedDateInDays(date, days) {
		date.setDate(date.getDate() - days);
		return date.getTime();
	},

	getDateRangeColumn(fromTimestamp, toTimestamp) {
		const range = getDateRangeArray(fromTimestamp, toTimestamp);
		return range.map(date => {
			return moment(date).format('MM/DD/YY')
		});
	},

	getNextBillingDate(timestamp, dateMetric) {
		const dateMetricsMap = {
			'Daily': 24 * 60 * 60 * 1000,
			'Monthly': 30 * 24 * 60 * 60 * 1000,
			'Yearly': 12 * 30 * 24 * 60 * 60 * 1000,
		};

		if (!dateMetricsMap.hasOwnProperty(dateMetric)) {
			throw new Error('Invalid date metric');
		}

		const addedValue = dateMetricsMap[dateMetric];
		let updatedTimestamp = timestamp + addedValue;

		if (['Monthly', 'Yearly'].includes(dateMetric)) {
			const startDate = new Date(timestamp);
			const adjustedStartDate = new Date(startDate.getTime() + addedValue + 1 * 24 * 60 * 60 * 1000);
			updatedTimestamp = adjustedStartDate.getTime();
		}

		return updatedTimestamp;
	},

	isDateToday(timestamp) {
		const date = new Date(timestamp);
		const today = new Date();
		return (
			date.getFullYear() === today.getFullYear() &&
			date.getMonth() === today.getMonth() &&
			date.getDate() === today.getDate()
		);
	}
}
