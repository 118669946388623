import firebase from 'firebase'
// Required for side-effects
require("firebase/firestore");

import config from '@/config/env-constants';
import devConfig from '@/config/dev.env';
import qaConfig from '@/config/qa.env';
import uatConfig from '@/config/uat.env';
import uat2Config from '@/config/uat2.env';
import uat3Config from '@/config/uat3.env';
import preprodConfig from '@/config/preprod.env';
import prodConfig from '@/config/prod.env';
import palletPoolingConfig from '@/config/pallet.pooling.env';
firebase.initializeApp(getEnvParam(config.currEnv));
firebase.analytics();

// Initialize Cloud Firestore through Firebase
const db = firebase.firestore();
const storage = firebase.storage();

const remoteConfig = firebase.remoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

export { firebase, db, storage, remoteConfig }

function getEnvParam(env) {
	if (env === 'dev') {
		return devConfig;
	} else if (env === 'qa') {
		return qaConfig;
	} else if (env === 'uat') {
		return uatConfig;
	} else if (env === 'uat2') {
		return uat2Config;
	} else if (env === 'uat3') {
		return uat3Config;
	} else if (env === 'preprod') {
		return preprodConfig;
	} else if (env === 'prod') {
		return prodConfig;
	} else if (env === 'pallet-pooling') {
		return palletPoolingConfig;
	}
}
